var render = function () {
  var _vm$$route, _vm$welcomePage, _vm$welcomePage2, _vm$$route2, _vm$$route2$path, _vm$$route3, _vm$$route3$path, _vm$profilePage, _vm$profilePage2, _vm$$route4, _vm$$route4$path;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    key: "gn-profile-menu-".concat(_vm.reRender),
    class: ['gn-profile-menu', _vm.isOpened ? 'gn-profile-menu--opened' : ''],
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_c('GenproxDropdownItem', {
    attrs: {
      "image": _vm.image || null,
      "label": _vm.label
    }
  }), _c('div', {
    staticClass: "gn-profile-menu__inner",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeDropdown.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "gn-profile-menu__dropdown"
  }, [_vm.welcomePage ? _c('router-link', {
    class: ['gn-profile-menu__link', ((_vm$$route = _vm.$route) === null || _vm$$route === void 0 ? void 0 : _vm$$route.name) === 'welcome' ? 'active' : ''],
    attrs: {
      "to": (_vm$welcomePage = _vm.welcomePage) === null || _vm$welcomePage === void 0 ? void 0 : _vm$welcomePage.route
    }
  }, [_vm._v(_vm._s(((_vm$welcomePage2 = _vm.welcomePage) === null || _vm$welcomePage2 === void 0 ? void 0 : _vm$welcomePage2.label) || 'Welcome'))]) : _vm._e(), _vm.profilePage ? _c('router-link', {
    class: ['gn-profile-menu__link', (_vm$$route2 = _vm.$route) !== null && _vm$$route2 !== void 0 && (_vm$$route2$path = _vm$$route2.path) !== null && _vm$$route2$path !== void 0 && _vm$$route2$path.includes('/profile') && !((_vm$$route3 = _vm.$route) !== null && _vm$$route3 !== void 0 && (_vm$$route3$path = _vm$$route3.path) !== null && _vm$$route3$path !== void 0 && _vm$$route3$path.includes('/profile/edit')) ? 'active' : ''],
    attrs: {
      "to": (_vm$profilePage = _vm.profilePage) === null || _vm$profilePage === void 0 ? void 0 : _vm$profilePage.route
    }
  }, [_vm._v(_vm._s(((_vm$profilePage2 = _vm.profilePage) === null || _vm$profilePage2 === void 0 ? void 0 : _vm$profilePage2.label) || 'My profile'))]) : _vm._e(), _vm.profilePage ? _c('router-link', {
    class: ['gn-profile-menu__link', (_vm$$route4 = _vm.$route) !== null && _vm$$route4 !== void 0 && (_vm$$route4$path = _vm$$route4.path) !== null && _vm$$route4$path !== void 0 && _vm$$route4$path.includes('/profile/edit') ? 'active' : ''],
    attrs: {
      "to": "/profile/edit/general"
    }
  }, [_vm._v("Settings")]) : _vm._e(), _c('div', {
    staticClass: "gn-profile-menu__link",
    on: {
      "click": function click($event) {
        return _vm.logout();
      }
    }
  }, [_vm._v("Logout")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }